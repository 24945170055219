import React, { useState } from 'react';
import SocialLinks from "../components/SocialLinks";
import { FaEnvelope, FaPhone, FaMapMarkerAlt } from 'react-icons/fa';
import { HiSupport } from 'react-icons/hi';
import { MdFeedback } from 'react-icons/md';

// Sample data for the cards
const dataCards = [
  {
    id: 1,
    title: 'Customer Support',
    description: 'Our support team is here to help you 24/7.',
    icon: <HiSupport className="w-12 h-12 text-blue-500" />,
  },
  {
    id: 2,
    title: 'Feedback',
    description: 'We value your feedback and suggestions.',
    icon: <MdFeedback className="w-12 h-12 text-blue-500" />,
  },
  {
    id: 3,
    title: 'Office Location',
    description: 'Visit us at our main office in Sydney.',
    icon: <FaMapMarkerAlt className="w-12 h-12 text-blue-500" />,
  },
];

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
    // Handle form submission logic here (e.g., API call)
  };

  return (
    <div className="max-w-6xl mx-auto mt-10 p-8 bg-gradient-to-r from-blue-500 to-indigo-600 rounded-lg shadow-lg dark:from-blue-700 dark:to-indigo-800">
      <h2 className="text-4xl font-bold text-white text-center mb-6">Contact Us</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="bg-white rounded-lg p-6 shadow-md dark:bg-gray-800">
          <h3 className="text-2xl font-semibold mb-4 text-gray-800 dark:text-gray-200">Get in Touch</h3>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1" htmlFor="name">
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300 dark:bg-gray-600 dark:border-gray-500 dark:text-white"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1" htmlFor="email">
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300 dark:bg-gray-600 dark:border-gray-500 dark:text-white"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1" htmlFor="message">
                Message
              </label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300 dark:bg-gray-600 dark:border-gray-500 dark:text-white"
                rows="4"
                required
              />
            </div>
            <button
              type="submit"
              className="w-full py-2 bg-blue-600 text-white font-semibold rounded-lg hover:bg-blue-700 transition duration-300 dark:bg-blue-500 dark:hover:bg-blue-600"
            >
              Send Message
            </button>
          </form>
        </div>

        {/* Additional Section for Contact Information */}
        <div className="bg-white rounded-lg p-6 shadow-lg dark:bg-gray-800">
  <h3 className="text-3xl font-bold mb-4 text-gray-800 dark:text-gray-200 text-center">Contact Information</h3>
  
  <div className="space-y-4">
    <div className="flex items-center">
      <FaMapMarkerAlt className="w-8 h-8 text-blue-500 dark:text-blue-400 mr-2" />
      <p className="text-gray-800 dark:text-gray-300">
        <strong>Address:</strong> 123 Main St, Sydney, NSW 2000
      </p>
    </div>
    
    <div className="flex items-center">
      <FaPhone className="w-8 h-8 text-blue-500 dark:text-blue-400 mr-2" />
      <p className="text-gray-800 dark:text-gray-300">
        <strong>Phone:</strong> (02) 1234 5678
      </p>
    </div>
    
    <div className="flex items-center">
      <FaEnvelope className="w-8 h-8 text-blue-500 dark:text-blue-400 mr-2" />
      <p className="text-gray-800 dark:text-gray-300">
        <strong>Email:</strong> contact@example.com
      </p>
    </div>
  </div>

  <h4 className="text-xl font-semibold mt-6 mb-2 text-gray-800 dark:text-gray-200 text-center">Follow Us</h4>
  <div className="flex justify-center space-x-4">
    <SocialLinks />
  </div>
</div>

      </div>

      {/* Data Cards Section */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-10">
        {dataCards.map((card) => (
          <div key={card.id} className="bg-white rounded-lg p-6 shadow-md flex items-start space-x-4 dark:bg-gray-800">
            {card.icon}
            <div>
              <h4 className="text-lg font-semibold text-gray-800 dark:text-gray-200">{card.title}</h4>
              <p className="text-gray-600 dark:text-gray-300">{card.description}</p>
              <button className="mt-2 text-blue-600 hover:text-blue-800 transition duration-300 dark:text-blue-400 dark:hover:text-blue-300">
                Learn More
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ContactUs;
