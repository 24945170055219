import React from "react";

const AboutSection = () => {
  return (
    <section className="py-10 bg-gray-100 dark:bg-gray-800 my-10">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col-reverse lg:flex-row items-center">
          <div className="lg:w-1/2 mb-10 lg:mb-0 p-5" >
            <h2 className="text-3xl font-bold text-red-500 dark:text-white mb-4 ">
              About Us
            </h2>
            <p className="text-gray-600 dark:text-gray-300 mb-6 ">
              Here at Whitehouse Education Zone, Bharatpur, we've been fostering
              academic excellence for over a decade. Established in 2068 B.S.,
              we've empowered countless students to achieve their educational
              goals. Now, with immense pride, we welcome you to join our vibrant
              learning community as we embark on our <span></span>14th successful year!
            </p>
            <p className="text-gray-600 dark:text-gray-300">
              We believe in a holistic approach to education, one that goes
              beyond rote learning. Our dedicated faculty provides personalized
              guidance and utilizes innovative teaching methods to ignite a
              passion for learning. We equip students with the knowledge,
              skills, and confidence they need to not only excel in competitive
              exams but also thrive in their chosen fields.
            </p>
          </div>
          <div className="lg:w-1/2">
            <img
              className="w-full h-auto rounded-lg shadow-lg transform transition duration-500 hover:scale-105"
              src="/img/about.jpg"
              alt="About Us"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
