import React, { useState } from 'react';
import { FaPlus, FaMinus } from 'react-icons/fa';

const FAQs = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqs = [
    {
      question: "What is Whitehouse Education Zone?",
      answer: "Whitehouse Education Zone is a premier educational institute located in Bharatpur, Chitwan, Nepal, dedicated to providing expert guidance and preparation for entrance exams in medical, engineering, and science fields.",
    },
    {
      question: "What courses do you offer?",
      answer: "Our offerings include MBBS Entrance Exam, B.Sc. Nursing Preparation, Engineering Entrance (BE/B.Arch), CTEVT Entrance Preparation, and more.",
    },
    {
      question: "Why should I choose Whitehouse Education Zone?",
      answer: "We provide expert faculty, comprehensive study materials, regular assessments, personalized guidance, and attractive scholarships.",
    },
    {
      question: "How can I enroll in a course?",
      answer: "You can enroll by visiting our campus, calling us, or completing the online registration form on our website.",
    },
    {
      question: "Do you offer scholarships?",
      answer: "Yes, we provide various scholarships based on merit and need. Visit our scholarships page for more information.",
    },
    {
      question: "What are the eligibility criteria for the courses?",
      answer: "Generally, completion of secondary education (10+2) with relevant subjects is required. Specific criteria are detailed on each course page.",
    },
    {
      question: "What is the duration of the courses?",
      answer: "The duration varies: MBBS Entrance Prep is 1 year, B.Sc. Nursing Prep is 1 year, and Engineering Entrance Prep is 6 months.",
    },
    {
      question: "Are there any placement services available?",
      answer: "Yes, we assist our students with placement services to help them secure admissions in top colleges.",
    },
    {
      question: "Can I access study materials online?",
      answer: "Yes, enrolled students can access comprehensive digital study materials through our online portal.",
    },
    {
      question: "What is the class schedule?",
      answer: "Class schedules vary by course and will be provided during the enrollment process.",
    },
  ];

  return (
    <div className="min-h-screen p-8 bg-white dark:bg-gray-900 transition-colors duration-300">
      <div className="flex justify-center mb-8">
        <h2 className="text-4xl font-bold text-black  dark:text-white">Frequently Asked Questions</h2>
      </div>
      <div className="w-full max-w-2xl shadow-lg rounded-lg p-6 mx-auto bg-gray-100 dark:bg-gray-800">
        {faqs.map((faq, index) => (
          <div key={index} className="border-b border-gray-300 dark:border-gray-600 py-4">
            <button
              className="flex items-center justify-between w-full text-left font-semibold text-lg text-black dark:text-white transition duration-300 ease-in-out focus:outline-none"
              onClick={() => toggleFAQ(index)}
            >
              {faq.question}
              {activeIndex === index ? <FaMinus className="text-blue-400" /> : <FaPlus className="text-blue-400" />}
            </button>
            {activeIndex === index && (
              <p className="mt-2 text-gray-700 dark:text-gray-400">{faq.answer}</p>
            )}
          </div>
        ))}
        
      </div>

      <hr className='my-10'/>
      <section className="mt-8 text-center">
        <h3 className="text-2xl font-semibold mb-4 text-black dark:text-white">Contact Us</h3>
        <p className="text-gray-400 dark:text-gray-300">For more inquiries, reach out to us at:</p>
        <p className="text-gray-400 dark:text-gray-300">Phone: +977-9845210977</p>
        <p className="text-gray-400 dark:text-gray-300">Email: contact@whitehouse.com.np</p>
      </section>
    </div>
  );
};

export default FAQs;
