import React, { useEffect, useRef } from 'react';
import { ReactTyped } from "react-typed";
import Lottie from "lottie-react";
import educationAnimation from '../Home/whitehouse.json';

const HeroSection = () => {
  const typedRef = useRef(null);

  useEffect(() => {
    if (typedRef.current) {
      typedRef.current.start();
    }
  }, [typedRef]);

  return (
    <div className="mt-5 relative flex flex-col md:flex-row items-center justify-between h-screen bg-gradient-to-r from-blue-500 to-blue-700 dark:from-blue-900 dark:to-blue-800 px-6">
      <div className="flex-1 flex flex-col justify-center text-white dark:text-gray-900 mb-10 md:mb-0 z-10">
        <h1 className="text-4xl md:text-6xl font-extrabold mb-4 animate__animated animate__fadeIn">
          Unlock Your Potential with Whitehouse Education Zone
        </h1>
        <ReactTyped
          ref={typedRef}
          strings={["Comprehensive Entrance Preparation", "Expert Guidance for Success", "Tailored Learning Experiences", "Achieve Your Academic Goals"]}
          typeSpeed={60}
          backSpeed={40}
          loop
          className="text-lg md:text-xl mb-6 animate__animated animate__fadeIn animate__delay-1s"
        />
        <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
          <a
            href="/programs-offered"
            className="bg-white text-blue-600 dark:bg-blue-600 dark:text-white px-8 py-4 rounded-lg shadow-lg font-semibold hover:bg-gray-200 dark:hover:bg-blue-500 transition duration-300 animate__animated animate__fadeIn animate__delay-2s"
          >
            Explore Our Courses
          </a>
          <a
            href="register"
            className="bg-green-500 text-white px-8 py-4 rounded-lg shadow-lg font-semibold hover:bg-green-600 transition duration-300 animate__animated animate__fadeIn animate__delay-3s"
          >
            Apply Online
          </a>
        </div>
      </div>

      <div className="flex-1 flex justify-center">
        <div className="relative">
          <div className="lottie w-full max-w-md animate__animated animate__zoomIn">
            <Lottie
              animationData={educationAnimation}
              loop
              style={{ width: '100%', height: '100%' }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
