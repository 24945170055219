import React, { useState } from 'react';
import { FaRegCalendarAlt } from 'react-icons/fa'; // Icon for the date

const NoticeBoard = () => {
  const [showAll, setShowAll] = useState(false); // State to toggle between latest and all notices

  const notices = [
    {
      title: "Upcoming Exam Schedule",
      date: "2024-09-30",
      description: "Please check the exam schedule for upcoming assessments. Ensure you are prepared and on time.",
    },
    {
      title: "New Course Enrollment",
      date: "2024-10-01",
      description: "Enroll in our new courses starting this October. Limited seats available!",
    },
    {
      title: "Holiday Notice",
      date: "2024-10-05",
      description: "Our institution will be closed for the holiday on October 5th. Enjoy your time off!",
    },
    {
      title: "Guest Lecture Event",
      date: "2024-10-15",
      description: "Join our guest lecture on Artificial Intelligence on October 15th!",
    },
    {
      title: "Library Maintenance",
      date: "2024-10-20",
      description: "The library will be closed for maintenance on October 20th. Sorry for the inconvenience!",
    },
  ];

  // Show latest three notices if 'showAll' is false
  const displayedNotices = showAll ? notices : notices.slice(0, 3);

  return (
    <section className="my-20 py-10 bg-gradient-to-br from-indigo-200 via-purple-200 to-pink-200 dark:bg-gradient-to-br dark:from-gray-800 dark:via-gray-900 dark:to-black">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <h2 className="text-4xl font-bold text-gray-900 dark:text-white mb-10 text-center">
          Notice Board
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {displayedNotices.map((notice, index) => (
            <div
              key={index}
              className="bg-white dark:bg-gray-900 rounded-xl shadow-lg p-6 transition-transform duration-300 hover:shadow-2xl hover:scale-105 transform-gpu relative overflow-hidden"
            >
              {/* Background Glow */}
              <div className="absolute inset-0 bg-gradient-to-tr from-indigo-400 to-purple-500 opacity-0 hover:opacity-50 transition-opacity duration-500 rounded-xl z-0"></div>

              {/* Notice Content */}
              <div className="relative z-10">
                <h3 className="text-2xl font-semibold text-gray-900 dark:text-white mb-3">
                  {notice.title}
                </h3>
                <div className="flex items-center text-gray-500 dark:text-gray-400 mb-4">
                  <FaRegCalendarAlt className="mr-2" />
                  <p className="text-sm">{notice.date}</p>
                </div>
                <p className="text-gray-700 dark:text-gray-400 leading-relaxed">
                  {notice.description}
                </p>
              </div>

              {/* Cool Gradient Border */}
              <div className="absolute inset-0 rounded-xl border-4 border-transparent hover:border-gradient-to-r hover:from-indigo-400 hover:to-purple-500 transition-all duration-500"></div>
            </div>
          ))}
        </div>

        {/* Show All Notices Button */}
        <div className="text-center mt-8">
          {!showAll ? (
            <button
              onClick={() => setShowAll(true)}
              className="px-6 py-2 bg-indigo-500 text-white font-semibold rounded-full hover:bg-indigo-600 transition-all duration-300"
            >
              See All Notices
            </button>
          ) : (
            <button
              onClick={() => setShowAll(false)}
              className="px-6 py-2 bg-indigo-500 text-white font-semibold rounded-full hover:bg-indigo-600 transition-all duration-300"
            >
              Show Less
            </button>
          )}
        </div>
      </div>
    </section>
  );
};

export default NoticeBoard;
