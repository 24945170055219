// src/firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { collection, getDocs } from "firebase/firestore"; // Import necessary Firestore methods

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);

// Function to check Firestore connection
const checkFirestoreConnection = async () => {
  try {
    // Attempt to get documents from a specific collection
    const snapshot = await getDocs(collection(db, "your_collection_name")); // Replace with your collection name
    if (snapshot.empty) {
      console.log("Firestore connected, but collection is empty.");
    } else {
      console.log("Firestore connected and documents retrieved:", snapshot.docs.map(doc => doc.data()));
    }
  } catch (error) {
    console.error("Error connecting to Firestore:", error);
  }
};

// Check Firestore connection
checkFirestoreConnection();

console.log("Firebase API Key:", firebaseConfig.apiKey); // Keep the API Key log

export { db, storage };
