import React from 'react';

const QuickLinks = () => {
  // Define the links array within the QuickLinks component
  const links = [
    { name: 'Medical Education Council', href: 'http://www.mec.gov.np', color: 'bg-blue-500' },
    { name: 'Agriculture and Forestry University', href: 'http://www.afu.edu.np', color: 'bg-green-500' },
    { name: 'Far Western University', href: 'http://www.fwu.edu.np', color: 'bg-yellow-500' },
    { name: 'Institute of Agriculture and Animal Science', href: 'http://www.iaas.edu.np', color: 'bg-red-500' },
    { name: 'Institute of Forestry', href: 'http://www.iof.edu.np', color: 'bg-purple-500' },
    { name: 'Institute of Engineering', href: 'http://www.ioe.edu.np', color: 'bg-pink-500' },
    { name: 'Institute of Science and Technology', href: 'https://iost.tu.edu.np/', color: 'bg-indigo-500' },
    { name: 'Pokhara University', href: 'http://www.pu.edu.np', color: 'bg-teal-500' },
    { name: 'Mid Western University', href: 'http://www.mwu.edu.np', color: 'bg-orange-500' },
  ];

  return (
    <div className=" dark:bg-gray-900 text-gray-900 dark:text-white  rounded-lg transition-colors duration-300">
      <h3 className="text-xl font-semibold mb-4 text-center underline">Quick Links</h3>
      <ul className="flex flex-wrap justify-center space-x-6">
        {links.map((link, index) => (
          <li key={index} className="group mb-4">
            <a
              href={link.href}
              className={`relative block text-gray-600 dark:text-gray-300 rounded-lg overflow-hidden transition-all duration-300 transform hover:scale-105`}
            >
              <span className="relative z-10 px-4 py-2">{link.name}</span>
              <span className="absolute inset-0 bg-gray-200 dark:bg-gray-800 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-left h-0.5"></span>
              <span className={`absolute inset-0 ${link.color} opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-lg shadow-lg`}></span>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default QuickLinks;
