import React, { useState, useEffect } from "react";
import { FaSun, FaMoon } from "react-icons/fa"; // Importing icons

const ThemeToggle = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  // Load theme from local storage
  useEffect(() => {
    const storedTheme = localStorage.getItem("theme");
    if (storedTheme === "dark") {
      setIsDarkMode(true);
      document.documentElement.classList.add("dark");
      document.body.classList.add("bg-gray-900"); // Dark background
    } else {
      document.body.classList.add("bg-white"); // Light background
    }
  }, []);

  const toggleTheme = () => {
    setIsDarkMode((prevMode) => !prevMode);
    document.documentElement.classList.toggle("dark");
    
    // Update body background based on the theme
    if (isDarkMode) {
      document.body.classList.remove("bg-gray-900");
      document.body.classList.add("bg-white");
      localStorage.setItem("theme", "light");
    } else {
      document.body.classList.remove("bg-white");
      document.body.classList.add("bg-gray-900");
      localStorage.setItem("theme", "dark");
    }
  };

  return (
    <button
      onClick={toggleTheme}
      className="p-2 mx-2 rounded-full bg-gray-300 dark:bg-gray-700 transition-all duration-300"
    >
      {isDarkMode ? (
        <FaSun className="text-yellow-500" />
      ) : (
        <FaMoon className="text-gray-800" />
      )}
    </button>
  );
};

export default ThemeToggle;
