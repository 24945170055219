import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import Navbar from "./Pages/Home/Navbar";
import FloatingContact from "./components/FloatingContact";
import Footer from "./Pages/Home/Footer";
import ScrollToTop from "./components/ScrollToTop";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <Navbar />
      <ScrollToTop />
      <App />
      <FloatingContact />
      <Footer />
    </Router>
  </React.StrictMode>
);

reportWebVitals();
