import React from 'react';

const JoinBroadcast = () => {
  return (
    <div className="bg-gradient-to-r from-blue-600 to-cyan-400 dark:from-blue-800 dark:to-cyan-700 rounded-lg p-6 text-center shadow-lg transition-transform transform hover:scale-105 hover:shadow-xl animate-fadeIn my-10">
      <h2 className="text-2xl font-bold text-white mb-2 dark:text-white">Join Our Broadcast Channel!</h2>
      <p className="text-lg text-white mb-4 dark:text-gray-200">
        Stay updated with our latest announcements and news. Join us on Messenger!
      </p>
      <a
        href="https://m.me/j/AbZK9zLAsVKth-jo/"
        target="_blank"
        rel="noopener noreferrer"
        className="bg-blue-800 text-white py-2 px-6 rounded-full font-semibold transition-colors duration-300 hover:bg-blue-600 dark:bg-blue-700 dark:hover:bg-blue-600"
      >
        Join Now
      </a>
    </div>
  );
};

export default JoinBroadcast;
