import React from "react";
import { FaShareAlt } from "react-icons/fa"; // Share icon
import { HiEye } from "react-icons/hi"; // Views icon
// import FeaturedBlog from "./Article/FeaturedBlog";

const Article = () => {
  const articles = [
    {
      id: 1,
      title: "Understanding React: A Beginner's Guide",
      author: "Jane Doe",
      date: "2024-09-25",
      views: 120,
      excerpt:
        "This article provides an overview of React, its components, and how to get started with it.",
      image: "https://via.placeholder.com/600x400", // Placeholder image for featured article
      category: "Featured",
    },
    {
      id: 2,
      title: "The Importance of Learning JavaScript",
      author: "John Smith",
      date: "2024-09-20",
      views: 150,
      excerpt:
        "JavaScript is a fundamental language for web development. This article explores its significance.",
      image: "https://via.placeholder.com/400x250",
      category: "Latest",
    },
    {
      id: 3,
      title: "10 Tips for Effective Online Learning",
      author: "Emily Johnson",
      date: "2024-09-18",
      views: 90,
      excerpt:
        "Discover essential strategies for maximizing your online learning experience.",
      image: "https://via.placeholder.com/400x250",
      category: "Recommended",
    },
    {
      id: 4,
      title: "Advanced CSS Techniques",
      author: "Alice Brown",
      date: "2024-09-15",
      views: 75,
      excerpt:
        "Explore advanced techniques in CSS to enhance your web development skills.",
      image: "https://via.placeholder.com/400x250",
      category: "Latest",
    },
    {
      id: 5,
      title: "Building Responsive Websites",
      author: "David Wilson",
      date: "2024-09-10",
      views: 200,
      excerpt:
        "Learn how to create responsive websites that work on all devices.",
      image: "https://via.placeholder.com/400x250",
      category: "Featured",
    },
  ];

  return (
    <section
      className="my-20 py-10 bg-gray-50 dark:bg-gray-800"
      itemScope
      itemType="http://schema.org/Blog">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <h2
          className="text-3xl font-bold text-gray-900 dark:text-white mb-8 text-center"
          itemProp="headline">
          Articles
        </h2>

        {/* Featured Articles */}
        <h3 className="text-2xl font-semibold text-gray-900 dark:text-white mb-4">
          Featured Articles
        </h3>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 mb-10">
          {articles
            .filter((article) => article.category === "Featured")
            .map((article) => (
              <div
                key={article.id}
                className="bg-white dark:bg-gray-900 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 p-6 col-span-1">
                <img
                  src={article.image}
                  alt={article.title}
                  className="w-full h-48 object-cover rounded-t-lg mb-4"
                />
                <h4
                  className="text-xl font-semibold text-gray-900 dark:text-white mb-2"
                  itemProp="name">
                  {article.title}
                </h4>
                <div className="flex items-center text-gray-500 dark:text-gray-400 mb-4">
                  <span className="mr-2" itemProp="author">
                    By {article.author}
                  </span>{" "}
                  |<span className="mx-2">{article.date}</span> |
                  <span className="flex items-center ml-2">
                    <HiEye className="mr-1" />
                    {article.views} Views
                  </span>
                </div>
                <p
                  className="text-gray-700 dark:text-gray-400 leading-relaxed mb-4"
                  itemProp="description">
                  {article.excerpt}
                </p>
                <div className="flex justify-between items-center">
                  <button className="text-indigo-600 hover:text-indigo-800 dark:hover:text-indigo-300">
                    Read More
                  </button>
                  <button className="text-gray-500 hover:text-gray-700 dark:hover:text-gray-300">
                    <FaShareAlt className="inline mr-1" /> Share
                  </button>
                </div>
              </div>
            ))}
        </div>

        {/* Latest Articles */}
        <h3 className="text-2xl font-semibold text-gray-900 dark:text-white mb-4">
          Latest Articles
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-10">
          {articles
            .filter((article) => article.category === "Latest")
            .map((article) => (
              <div
                key={article.id}
                className="bg-white dark:bg-gray-900 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 p-6">
                <img
                  src={article.image}
                  alt={article.title}
                  className="w-full h-48 object-cover rounded-t-lg mb-4"
                />
                <h4 className="text-xl font-semibold text-gray-900 dark:text-white mb-2">
                  {article.title}
                </h4>
                <div className="flex items-center text-gray-500 dark:text-gray-400 mb-4">
                  <span className="mr-2">By {article.author}</span> |
                  <span className="mx-2">{article.date}</span> |
                  <span className="flex items-center ml-2">
                    <HiEye className="mr-1" />
                    {article.views} Views
                  </span>
                </div>
                <p className="text-gray-700 dark:text-gray-400 leading-relaxed mb-4">
                  {article.excerpt}
                </p>
                <div className="flex justify-between items-center">
                  <button className="text-indigo-600 hover:text-indigo-800 dark:hover:text-indigo-300">
                    Read More
                  </button>
                  <button className="text-gray-500 hover:text-gray-700 dark:hover:text-gray-300">
                    <FaShareAlt className="inline mr-1" /> Share
                  </button>
                </div>
              </div>
            ))}
        </div>

        {/* Recommended Articles */}
        <h3 className="text-2xl font-semibold text-gray-900 dark:text-white mb-4">
          Recommended Articles
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {articles
            .filter((article) => article.category === "Recommended")
            .map((article) => (
              <div
                key={article.id}
                className="bg-white dark:bg-gray-900 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 p-6">
                <img
                  src={article.image}
                  alt={article.title}
                  className="w-full h-48 object-cover rounded-t-lg mb-4"
                />
                <h4 className="text-xl font-semibold text-gray-900 dark:text-white mb-2">
                  {article.title}
                </h4>
                <div className="flex items-center text-gray-500 dark:text-gray-400 mb-4">
                  <span className="mr-2">By {article.author}</span> |
                  <span className="mx-2">{article.date}</span> |
                  <span className="flex items-center ml-2">
                    <HiEye className="mr-1" />
                    {article.views} Views
                  </span>
                </div>
                <p className="text-gray-700 dark:text-gray-400 leading-relaxed mb-4">
                  {article.excerpt}
                </p>
                <div className="flex justify-between items-center">
                  <button className="text-indigo-600 hover:text-indigo-800 dark:hover:text-indigo-300">
                    Read More
                  </button>
                  <button className="text-gray-500 hover:text-gray-700 dark:hover:text-gray-300">
                    <FaShareAlt className="inline mr-1" /> Share
                  </button>
                </div>
              </div>
            ))}
        </div>
      </div>
      
    </section>
  );
};

export default Article;
