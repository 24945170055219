"use client";

import { useState, Fragment, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import ThemeToggle from "../../components/ThemeToggle";
import { Link } from "react-router-dom";

const navigation = [
  { name: "Home", to: "/" },
  { name: "About Us", to: "/about-us" },
  { name: "Notice", to: "/notice" },
  { name: "Gallery", to: "/gallery" },
  { name: "Program", to: "/programs-offered" },
  { name: "Articles", to: "/article" },
  { name: "FAQs", to: "/faqs" },
];

export default function Navbar() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [hasShadow, setHasShadow] = useState(false);

  // Handle scroll event to toggle shadow class on navbar
  useEffect(() => {
    const handleScroll = () => {
      setHasShadow(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <header
        className={`sticky top-0 z-50 bg-white dark:bg-gray-800 transition-shadow duration-300 ${
          hasShadow ? "shadow-md" : ""
        }`}>
        <nav className="flex items-center justify-between p-5 lg:px-8">
          <div className="flex lg:flex-1 items-center justify-center">
            <Link to="/" className="-m-1.5 p-1.5 pt-0">
              <img src="/img/logo.png" alt="Logo" className="h-12 w-auto" />
            </Link>
          </div>

          <div className="lg:hidden">
            <ThemeToggle />
            <button
              onClick={() => setMobileMenuOpen(true)}
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700 dark:text-gray-300">
              <Bars3Icon className="h-6 w-6" />
            </button>
          </div>

          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
              <Link
                key={item.name}
                to={item.to}
                className="text-sm font-semibold text-gray-900 dark:text-gray-200 hover:text-red-500 hover:dark:text-red-400">
                {item.name}
              </Link>
            ))}
          </div>

          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            <ThemeToggle />
            <Link to="/register" className="text-sm font-semibold text-white bg-blue-900 hover:bg-blue-500 transition duration-500 rounded-xl px-4 py-3">
              Apply Online <span aria-hidden="true">&rarr;</span>
            </Link>
          </div>
        </nav>

        <Transition.Root show={mobileMenuOpen} as={Fragment}>
          <Dialog as="div" className="lg:hidden" onClose={setMobileMenuOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <div className="fixed inset-0 z-50 bg-gray-500 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-50 overflow-y-auto">
              <div className="relative z-50 inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-300 sm:duration-500"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-300 sm:duration-500"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full">
                    <Dialog.Panel className="pointer-events-auto w-screen max-w-md bg-white dark:bg-gray-800">
                      <div className="flex h-full flex-col py-6 shadow-xl relative z-50">
                        <div className="flex justify-between px-4 sm:px-6">
                          <Link to="whitehouse.com.np" className="-m-1.5 p-1.5">
                            <img
                              src="/img/logo.png"
                              alt="Logo"
                              className="h-8 w-auto"
                            />
                          </Link>
                          <button
                            onClick={() => setMobileMenuOpen(false)}
                            className="text-gray-400 hover:text-gray-500 dark:text-gray-300">
                            <XMarkIcon className="h-6 w-6" />
                          </button>
                        </div>
                        <div className="flex-1 mt-6 px-4 sm:px-6">
                          {navigation.map((item) => (
                            <Link
                              key={item.name}
                              to={item.to}
                              onClick={() => setMobileMenuOpen(false)}
                              className="block py-2 px-3 text-base font-semibold text-gray-900 hover:bg-gray-50 dark:text-gray-200 dark:hover:bg-gray-700">
                              {item.name}
                            </Link>
                          ))}
                          <hr className="my-5 shadow-xl" />
                          <Link
                            to="/register"
                            onClick={() => setMobileMenuOpen(false)}
                            className="block py-2.5 px-3 text-base font-semibold bg-blue-500 text-white rounded-md shadow-xl transition duration-500 hover:bg-blue-800">
                            Apply Online
                          </Link>

                          <p className="absolute bottom-10 text-center uppercase text-gray-900 dark:text-gray-200">
                            <hr className="w-full" />
                            Whitehouse Education Zone Bharatpur
                            <hr />
                          </p>
                        </div>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </header>
    </>
  );
}
