import React, { useState, useEffect } from "react";
import { db } from "../firebase";
import { collection, addDoc } from "firebase/firestore";

const RegistrationForm = () => {
    const [formData, setFormData] = useState({
        fullName: "",
        collegeName: "",
        address: "",
        contactNumber: "",
        program: "",
        class: "",
        dob: "",
        email: "",
    });

    const [errors, setErrors] = useState({});
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showErrorAlerts, setShowErrorAlerts] = useState([]);
    const [loading, setLoading] = useState(false);

    // Program options
    const programOptions = [
        "Tuition",
        "Bridge Course",
        "CTEVT Entrance Preparation",
        "License Preparation",
        "CEE Preparation",
        "Applied Science Preparation",
        "Engineering",
    ];

    useEffect(() => {
        const checkConnection = async () => {
            try {
                console.log("Connected to the Firestore database.");
            } catch (error) {
                console.error("Error connecting to Firestore: ", error);
            }
        };
        checkConnection();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      const validationErrors = validateForm();
  
      // Log validation errors
      console.log("Validation Errors: ", validationErrors);
  
      if (Object.keys(validationErrors).length === 0) {
          setLoading(true);
          try {
              await addDoc(collection(db, "website-register"), {
                  ...formData,
              });
  
              setShowSuccessAlert(true);
              window.scrollTo(0, 0);
  
              // Set timeout for success alert
              setTimeout(() => {
                  setShowSuccessAlert(false);
                  resetForm();
              }, 5000);
          } catch (error) {
              // Show error alert
              setShowErrorAlerts((prev) => [
                  ...prev,
                  "Registration failed! Please try again.",
              ]);
              console.error("Error registering user:", error);
              
              // Set timeout for error alerts
              setTimeout(() => {
                  setShowErrorAlerts([]);
              }, 5000);
          } finally {
              setLoading(false);
          }
      } else {
          setErrors(validationErrors);
          setShowErrorAlerts(Object.values(validationErrors));
  
          // Optional: Set timeout for validation error alerts
          setTimeout(() => {
              setShowErrorAlerts([]);
          }, 5000);
      }
  };
  

    // Form validation function
    const validateForm = () => {
        let validationErrors = {};

        // Check if fullName is provided
        if (!formData.fullName.trim()) {
            validationErrors.fullName = "Full Name is required.";
        }

        // Validate email
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(formData.email)) {
            validationErrors.email = "Please enter a valid email address.";
        }

        // Validate contact number
        const phonePattern = /^[0-9]{10}$/;
        if (!formData.contactNumber.trim()) {
            validationErrors.contactNumber = "Phone Number is required.";
        } else if (!phonePattern.test(formData.contactNumber)) {
            validationErrors.contactNumber = "Please enter a valid 10-digit contact number.";
        }

        // Check for other fields
        if (!formData.collegeName.trim()) {
            validationErrors.collegeName = "College Name is required.";
        }
        if (!formData.address.trim()) {
            validationErrors.address = "Address is required.";
        }
        if (!formData.program.trim()) {
            validationErrors.program = "Program is required.";
        }
        if (!formData.class.trim()) {
            validationErrors.class = "Class is required.";
        }
        if (!formData.dob.trim()) {
            validationErrors.dob = "Date of Birth is required.";
        }

        return validationErrors;
    };

    // Function to reset form data
    const resetForm = () => {
        setFormData({
            fullName: "",
            collegeName: "",
            address: "",
            contactNumber: "",
            program: "",
            class: "",
            dob: "",
            email: "",
        });
        setErrors({});
        setShowErrorAlerts([]);
    };

    return (
        <div className="font-sans bg-gradient-to-r from-blue-400 via-indigo-500 to-purple-600 min-h-screen flex flex-col justify-center items-center p-6 dark:from-gray-800 dark:via-gray-900 dark:to-black">
            {/* Success Alert */}
            {showSuccessAlert && (
                <div className="z-50 fixed top-5 right-5 p-4 bg-green-600 text-white rounded-lg shadow-lg transition-opacity duration-500 ease-in-out transform translate-y-0 opacity-100">
                    <div className="flex items-center">
                        <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M10 15l-5-5h3V0h4v10h3l-5 5z" />
                        </svg>
                        <span>🎉 Registration Successful!</span>
                    </div>
                </div>
            )}

            {/* Error Alerts */}
            {showErrorAlerts.length > 0 && (
                <div className="z-50 fixed top-5 right-5 p-4 bg-red-600 text-white rounded-lg shadow-lg transition-opacity duration-500 ease-in-out transform translate-y-0 opacity-100">
                    <div className="flex items-center">
                        <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M10 0L0 20h20L10 0zM9 15h2v2H9v-2zm0-10h2v8H9V5z" />
                        </svg>
                        <span>❌ {showErrorAlerts.join(", ")}</span>
                    </div>
                </div>
            )}

            <div className="w-full max-w-4xl bg-white bg-opacity-90 shadow-2xl rounded-lg p-8 transition-transform transform dark:bg-gray-900 dark:bg-opacity-80">
                <h3 className="text-gray-900 text-4xl font-extrabold mb-6 text-center bg-gradient-to-r from-purple-400 to-blue-400 text-transparent bg-clip-text dark:from-blue-500 dark:to-purple-600">
                    Register Now
                </h3>
                <form className="space-y-6" onSubmit={handleSubmit}>
                    {/* Full Name */}
                    <div>
                        <label className="text-gray-700 text-sm font-semibold mb-2 block dark:text-gray-300">Full Name</label>
                        <input
                            name="fullName"
                            type="text"
                            required
                            className={`w-full p-3 bg-gray-100 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-indigo-400 focus:border-indigo-400 transition duration-200 dark:bg-gray-800 dark:border-gray-600 dark:text-gray-300 dark:focus:ring-indigo-500 ${errors.fullName ? "border-red-500" : ""}`}
                            placeholder="Enter your full name"
                            value={formData.fullName}
                            onChange={handleChange}
                        />
                        {errors.fullName && <p className="text-red-500 text-xs mt-1">{errors.fullName}</p>}
                    </div>

                    {/* Email */}
                    <div>
                        <label className="text-gray-700 text-sm font-semibold mb-2 block dark:text-gray-300">Email</label>
                        <input
                            name="email"
                            type="email"
                            required
                            className={`w-full p-3 bg-gray-100 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-indigo-400 focus:border-indigo-400 transition duration-200 dark:bg-gray-800 dark:border-gray-600 dark:text-gray-300 dark:focus:ring-indigo-500 ${errors.email ? "border-red-500" : ""}`}
                            placeholder="Enter your email"
                            value={formData.email}
                            onChange={handleChange}
                        />
                        {errors.email && <p className="text-red-500 text-xs mt-1">{errors.email}</p>}
                    </div>

                    {/* Phone Number */}
                    <div>
                        <label className="text-gray-700 text-sm font-semibold mb-2 block dark:text-gray-300">Contact Number</label>
                        <input
                            name="contactNumber"
                            type="text"
                            required
                            className={`w-full p-3 bg-gray-100 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-indigo-400 focus:border-indigo-400 transition duration-200 dark:bg-gray-800 dark:border-gray-600 dark:text-gray-300 dark:focus:ring-indigo-500 ${errors.contactNumber ? "border-red-500" : ""}`}
                            placeholder="Enter your contact number"
                            value={formData.contactNumber}
                            onChange={handleChange}
                        />
                        {errors.contactNumber && <p className="text-red-500 text-xs mt-1">{errors.contactNumber}</p>}
                    </div>

                    {/* College Name */}
                    <div>
                        <label className="text-gray-700 text-sm font-semibold mb-2 block dark:text-gray-300">College Name</label>
                        <input
                            name="collegeName"
                            type="text"
                            required
                            className={`w-full p-3 bg-gray-100 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-indigo-400 focus:border-indigo-400 transition duration-200 dark:bg-gray-800 dark:border-gray-600 dark:text-gray-300 dark:focus:ring-indigo-500 ${errors.collegeName ? "border-red-500" : ""}`}
                            placeholder="Enter your college name"
                            value={formData.collegeName}
                            onChange={handleChange}
                        />
                        {errors.collegeName && <p className="text-red-500 text-xs mt-1">{errors.collegeName}</p>}
                    </div>

                    {/* Address */}
                    <div>
                        <label className="text-gray-700 text-sm font-semibold mb-2 block dark:text-gray-300">Address</label>
                        <input
                            name="address"
                            type="text"
                            required
                            className={`w-full p-3 bg-gray-100 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-indigo-400 focus:border-indigo-400 transition duration-200 dark:bg-gray-800 dark:border-gray-600 dark:text-gray-300 dark:focus:ring-indigo-500 ${errors.address ? "border-red-500" : ""}`}
                            placeholder="Enter your address"
                            value={formData.address}
                            onChange={handleChange}
                        />
                        {errors.address && <p className="text-red-500 text-xs mt-1">{errors.address}</p>}
                    </div>

                    {/* Program */}
                    <div>
                        <label className="text-gray-700 text-sm font-semibold mb-2 block dark:text-gray-300">Program</label>
                        <select
                            name="program"
                            required
                            className={`w-full p-3 bg-gray-100 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-indigo-400 focus:border-indigo-400 transition duration-200 dark:bg-gray-800 dark:border-gray-600 dark:text-gray-300 dark:focus:ring-indigo-500 ${errors.program ? "border-red-500" : ""}`}
                            value={formData.program}
                            onChange={handleChange}
                        >
                            <option value="" disabled>Select your program</option>
                            {programOptions.map((option) => (
                                <option key={option} value={option}>{option}</option>
                            ))}
                        </select>
                        {errors.program && <p className="text-red-500 text-xs mt-1">{errors.program}</p>}
                    </div>

                    {/* Class */}
                    <div>
                        <label className="text-gray-700 text-sm font-semibold mb-2 block dark:text-gray-300">Class</label>
                        <input
                            name="class"
                            type="text"
                            required
                            className={`w-full p-3 bg-gray-100 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-indigo-400 focus:border-indigo-400 transition duration-200 dark:bg-gray-800 dark:border-gray-600 dark:text-gray-300 dark:focus:ring-indigo-500 ${errors.class ? "border-red-500" : ""}`}
                            placeholder="Enter your class"
                            value={formData.class}
                            onChange={handleChange}
                        />
                        {errors.class && <p className="text-red-500 text-xs mt-1">{errors.class}</p>}
                    </div>

                    {/* Date of Birth */}
                    <div>
                        <label className="text-gray-700 text-sm font-semibold mb-2 block dark:text-gray-300">Date of Birth</label>
                        <input
                            name="dob"
                            type="date"
                            required
                            className={`w-full p-3 bg-gray-100 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-indigo-400 focus:border-indigo-400 transition duration-200 dark:bg-gray-800 dark:border-gray-600 dark:text-gray-300 dark:focus:ring-indigo-500 ${errors.dob ? "border-red-500" : ""}`}
                            value={formData.dob}
                            onChange={handleChange}
                        />
                        {errors.dob && <p className="text-red-500 text-xs mt-1">{errors.dob}</p>}
                    </div>

                    <button
                        type="submit"
                        className={`w-full bg-blue-600 text-white font-bold py-2 rounded-lg shadow hover:bg-blue-700 transition duration-200 ease-in-out ${loading ? "opacity-50 cursor-not-allowed" : ""}`}
                        disabled={loading}
                    >
                        {loading ? "Registering..." : "Register"}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default RegistrationForm;
