import React from 'react';

// Sample logos (replace with actual logo URLs)
const logos = {
  bridgeCourse: 'https://via.placeholder.com/100?text=Bridge+Course',
  ctevEntrance: 'https://via.placeholder.com/100?text=CTEVT+Entrance',
  ceeEntrance: 'https://via.placeholder.com/100?text=CEE+Entrance',
  appliedScience: 'https://via.placeholder.com/100?text=Applied+Science',
  preMedical: 'https://via.placeholder.com/100?text=Pre-Medical',
  engineering: 'https://via.placeholder.com/100?text=Engineering',
  tuition: 'https://via.placeholder.com/100?text=Tuition',
  computerClass: 'https://via.placeholder.com/100?text=Computer+Class',
};

// Different gradients for each program
const gradients = [
  'bg-gradient-to-r from-green-400 to-blue-500', // Bridge Course
  'bg-gradient-to-r from-red-400 to-yellow-500', // CTEVT Entrance
  'bg-gradient-to-r from-purple-400 to-pink-500', // CEE Entrance
  'bg-gradient-to-r from-blue-400 to-teal-500', // Applied Science
  'bg-gradient-to-r from-indigo-400 to-purple-500', // Pre-Medical
  'bg-gradient-to-r from-yellow-400 to-red-500', // Engineering
  'bg-gradient-to-r from-gray-400 to-gray-500', // Tuition
  'bg-gradient-to-r from-pink-400 to-purple-500', // Computer Class
];

// Dynamic SEO descriptions for each program
const seoDescriptions = {
  "Bridge Course (After SEE)": "Enroll in our Bridge Course to seamlessly transition from SEE to higher education, ensuring you have the foundational knowledge required for your academic journey.",
  "CTEVT Entrance Preparation": "Prepare for the CTEVT Entrance Exam with our expert-led coaching, designed to boost your confidence and ensure you excel in your assessments.",
  "CEE Entrance Preparation": "Ace the CEE Entrance Exam with our tailored curriculum, offering comprehensive resources and support to help you achieve your academic goals.",
  "Applied Science Entrance Preparation": "Join our Applied Science Entrance Preparation program to gain the necessary skills and knowledge to excel in your higher studies and future career.",
  "Pre-Medical and Engineering Class": "Kickstart your journey in medicine or engineering with our Pre-Medical and Engineering Class, where we provide targeted training to help you succeed in competitive exams.",
  "Engineering Entrance Preparation": "Get ready for the Engineering Entrance Exams with our focused training sessions, equipping you with the skills and knowledge to outperform in your assessments.",
  "Tuition/Coaching Class": "Enhance your understanding and performance in various subjects with our Tuition and Coaching classes, tailored to meet your individual learning needs.",
  "Computer Class": "Master essential computer skills in our dedicated Computer Class, designed to provide you with the knowledge needed for today's digital world.",
};

const programs = [
  { name: "Bridge Course (After SEE)", logo: logos.bridgeCourse },
  { name: "CTEVT Entrance Preparation", logo: logos.ctevEntrance },
  { name: "CEE Entrance Preparation", logo: logos.ceeEntrance },
  { name: "Applied Science Entrance Preparation", logo: logos.appliedScience },
  { name: "Pre-Medical and Engineering Class", logo: logos.preMedical },
  { name: "Engineering Entrance Preparation", logo: logos.engineering },
  { name: "Tuition/Coaching Class", logo: logos.tuition },
  { name: "Computer Class", logo: logos.computerClass },
];

const ProgramShowcase = () => {
  return (
    <section className="py-20  dark:bg-gray-900 my-10">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <h2 className="text-4xl text-center text-gray-900 dark:text-white font-bold mb-10">
          Programs Offered by Whitehouse Education
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
          {programs.map((program, index) => (
            <div
              key={index}
              className={`${gradients[index]} shadow-lg rounded-lg overflow-hidden transform transition duration-300 hover:scale-105`}
            >
              <img
                src={program.logo}
                alt={program.name}
                className="w-full h-32 object-cover"
              />
              <div className="p-4">
                <h3 className="text-lg font-semibold text-white mb-2">
                  {program.name}
                </h3>
                <p className="text-gray-200">
                  {seoDescriptions[program.name]}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ProgramShowcase;
