const socialLinks = [
  {
    href: "https://www.facebook.com/WhitehouseBridgeCourse",
    imgSrc: "img/facebook-icon.png",
    alt: "Facebook",
  },
  {
    href: "https://www.tiktok.com/@whitehouseeducationzone",
    imgSrc: "img/tiktok-icon.png",
    alt: "TikTok",
  },
  {
    href: "https://www.instagram.com/whitehouseeducationzone",
    imgSrc: "img/instagram-icon.png",
    alt: "Instagram",
  },
  {
    href: "https://www.youtube.com/@whitehouseeducationzone",
    imgSrc: "img/youtube-icon.png",
    alt: "YouTube",
  },
  {
    href: "https://play.google.com/store/apps/details?id=com.yourapp",
    imgSrc: "img/playstore-icon.webp",
    alt: "Download on Play Store",
  },
];
export default function SocialLinks() {
  return (
    <div className="flex justify-center mb-4">
      {socialLinks.map((social) => (
        <a key={social.alt} href={social.href} className="mx-2" target="_blank" rel="noreferrer">
          <img src={social.imgSrc} alt={social.alt} className="h-6" />
        </a>
      ))}
    </div>
  );
}
