import React, { useState } from "react";

const images = [
  "https://scontent.fktm17-1.fna.fbcdn.net/v/t39.30808-6/449161396_995058612625173_745497459679263037_n.jpg?_nc_cat=109&ccb=1-7&_nc_sid=f727a1&_nc_ohc=8-gmpRXRm9QQ7kNvgGszj1D&_nc_ht=scontent.fktm17-1.fna&_nc_gid=AWMOZDygB-smXrkmfx-qTO8&oh=00_AYBXutzhY9wYMcHGAOKbaOSxwHBjzZN-uoWQI_wB8wNG3w&oe=66FCB1E2",
  "https://scontent.fktm17-1.fna.fbcdn.net/v/t39.30808-6/448401322_988451423285892_4178191559241740581_n.jpg?stp=dst-jpg_s417x417&_nc_cat=110&ccb=1-7&_nc_sid=f727a1&_nc_ohc=Pr6leqPtf14Q7kNvgGbt1kP&_nc_ht=scontent.fktm17-1.fna&oh=00_AYDDANWqnPfiHL13MHMJoDmIDtp61fC6shAhTx69MrtFqQ&oe=66FCCB7F",
  "https://scontent.fktm17-1.fna.fbcdn.net/v/t39.30808-6/448396493_988451673285867_5954400912515912678_n.jpg?_nc_cat=109&ccb=1-7&_nc_sid=f727a1&_nc_ohc=p-jax9XngKEQ7kNvgHJAxmA&_nc_ht=scontent.fktm17-1.fna&_nc_gid=AEXV0xlKM60FpD9-UDIMvrH&oh=00_AYDcmBAGHF47-tooJxYGBLbOfUltHig7XKwIdZRgEDmfJg&oe=66FCC626",
  "https://scontent.fktm17-1.fna.fbcdn.net/v/t39.30808-6/448493882_988451816619186_6288674916314701153_n.jpg?_nc_cat=106&ccb=1-7&_nc_sid=f727a1&_nc_ohc=bPpmLeE-i-YQ7kNvgF8o9XX&_nc_ht=scontent.fktm17-1.fna&oh=00_AYDrS1f7n5952-yBDzseKbOSvGZp24gjKDiZgTOINhkH1g&oe=66FCA368",
  "https://scontent.fktm17-1.fna.fbcdn.net/v/t39.30808-6/442427097_969164038547964_1161147481124642263_n.jpg?_nc_cat=105&ccb=1-7&_nc_sid=f727a1&_nc_ohc=z6rtiwShKOMQ7kNvgEwoJ0o&_nc_ht=scontent.fktm17-1.fna&_nc_gid=Aa4wRwkmRK8fdHJjbQj-wGz&oh=00_AYDcfTqrfwLmbHpHgR3xPUmvo-GUnS9p_Rp3nh-F1kx84Q&oe=66FCB62A",
  "https://scontent.fktm17-1.fna.fbcdn.net/v/t39.30808-6/442419366_969164095214625_3492055338589400113_n.jpg?_nc_cat=107&ccb=1-7&_nc_sid=f727a1&_nc_ohc=MWGRumf9EpMQ7kNvgHqWSwI&_nc_ht=scontent.fktm17-1.fna&_nc_gid=A9hMEZcuNnHR17P_pjyKUzJ&oh=00_AYAXkdLVlopwa3UCr0_pTOQLBvnALEN4dxb5MnFjlDB3fA&oe=66FCC4F2",
  "https://scontent.fktm17-1.fna.fbcdn.net/v/t39.30808-6/441455857_969164161881285_6153015555717999714_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=f727a1&_nc_ohc=evheze3WACEQ7kNvgHl1wuF&_nc_ht=scontent.fktm17-1.fna&oh=00_AYCouayNssL8qm9jc2z0SmYQ0fOsLP3knKMSvXVYKJb-Og&oe=66FCCA5E",
  "https://scontent.fktm17-1.fna.fbcdn.net/v/t39.30808-6/443713289_969164291881272_7182210317511558426_n.jpg?_nc_cat=104&ccb=1-7&_nc_sid=f727a1&_nc_ohc=uffklIY6iscQ7kNvgGXDp8S&_nc_ht=scontent.fktm17-1.fna&_nc_gid=AKItXAVMqnaOGMFA0NnN2IF&oh=00_AYAMH4GnAZ4qOBXtxumY8JgzvrxX6b398YR62rvplmm8pg&oe=66FCA589",
  "https://scontent.fktm17-1.fna.fbcdn.net/v/t39.30808-6/440577238_959072156223819_8230472320834877699_n.jpg?_nc_cat=103&ccb=1-7&_nc_sid=f727a1&_nc_ohc=Umg8YXCNsA4Q7kNvgHVye2K&_nc_ht=scontent.fktm17-1.fna&_nc_gid=AIf1ozvNTM5CHVEKkCBbRfQ&oh=00_AYBWSui3JGaPzJjOVTHb1wLXf0F1ODLUcUrB2W0_L2eCaw&oe=66FCCAFB",
  "https://scontent.fktm17-1.fna.fbcdn.net/v/t39.30808-6/440809340_959072272890474_1534151256320999232_n.jpg?_nc_cat=101&ccb=1-7&_nc_sid=f727a1&_nc_ohc=mwxs_5WFqx0Q7kNvgGUIh0E&_nc_ht=scontent.fktm17-1.fna&_nc_gid=AS5oG8oVohpeDQ0BvmHfEd4&oh=00_AYD1y-hafGVKMuU7dR6axgaVHvI9rjNNXZfLTDgG57g8Vw&oe=66FC9CD8",
  "https://scontent.fktm17-1.fna.fbcdn.net/v/t39.30808-6/440581804_959072372890464_1086242809570639296_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=f727a1&_nc_ohc=LS7434ygC1AQ7kNvgGtz2hQ&_nc_ht=scontent.fktm17-1.fna&_nc_gid=A9cueur8OjLVzXTvSG9FEM1&oh=00_AYA0s2s1gBm79ONPgO9SawHZRdrwDxvIpZQsmjCcWXE-Pw&oe=66FCA990",
  "https://scontent.fktm17-1.fna.fbcdn.net/v/t39.30808-6/440582547_959072476223787_6660673328824745499_n.jpg?_nc_cat=101&ccb=1-7&_nc_sid=f727a1&_nc_ohc=eyVN3No9-fIQ7kNvgGZapv5&_nc_ht=scontent.fktm17-1.fna&oh=00_AYAJYnMdtKqDlW61WYZjONNxHwut7azSaX1oDxl-Rvjbqw&oe=66FCBFB9",
  "https://scontent.fktm17-1.fna.fbcdn.net/v/t39.30808-6/440399505_959072509557117_796376688233565152_n.jpg?_nc_cat=100&ccb=1-7&_nc_sid=f727a1&_nc_ohc=tnVTUFuBHMQQ7kNvgFXprTA&_nc_ht=scontent.fktm17-1.fna&_nc_gid=ASzOHvVNo_ualiAS-rbB4OE&oh=00_AYD5SA3TkAC3V8kQAbEplilyo0rmsd3rDYGmgNVOVXOAEQ&oe=66FCB66C",
  "https://scontent.fktm17-1.fna.fbcdn.net/v/t39.30808-6/440571196_959072552890446_7740602372125045438_n.jpg?_nc_cat=109&ccb=1-7&_nc_sid=f727a1&_nc_ohc=A7jBVzjEsmgQ7kNvgFhYXbE&_nc_ht=scontent.fktm17-1.fna&_nc_gid=AI3zfVMt00PqUNpZWmAoQe4&oh=00_AYBj8IW9U6qwZP-eolXDxzlKoFBUO90kzb25HKBEaGXYsA&oe=66FCA9A8",
  "https://scontent.fktm17-1.fna.fbcdn.net/v/t39.30808-6/440577402_959072599557108_2597738830277195165_n.jpg?_nc_cat=103&ccb=1-7&_nc_sid=f727a1&_nc_ohc=NcxI553q2mkQ7kNvgFq2aV4&_nc_ht=scontent.fktm17-1.fna&_nc_gid=AlfUKnFpyU2DF9Pj_s-2kv6&oh=00_AYCppdZzh18KOAByWBVCheD7p0cmBkfiXhJb3ArETUjt-g&oe=66FCBF82",
];

const ImageGallery = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  return (
    <>
      <section className="py-10 mt-20 dark:bg-gray-800" id="ImageGallery">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="mb-16 text-center">
            <span className="text-sm text-indigo-600 font-medium block mb-2 dark:text-indigo-400">
              Gallery
            </span>
            <h2 className="text-4xl text-gray-900 font-bold dark:text-white">
              Celebrating Our Students!
            </h2>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            {images.map((image, index) => (
              <div key={index} onClick={() => handleImageClick(image)}>
                <img
                  className="h-auto max-w-full rounded-lg cursor-pointer hover:shadow-lg transition-shadow duration-200"
                  src={image}
                  alt={` ${index + 1}`}
                />
              </div>
            ))}
          </div>

          {selectedImage && (
            <div className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50">
              <div className="relative">
                <img
                  className="max-w-full max-h-screen object-contain"
                  src={selectedImage}
                  alt="Full Screen"
                />
                <button
                  onClick={handleCloseModal}
                  className="absolute top-4 right-4 text-white bg-red-500 rounded-full p-2 focus:outline-none">
                  &times;
                </button>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default ImageGallery;
