import React from "react";
import { FaRegClock } from "react-icons/fa";

const AboutUs = () => {
  return (
    <div className="bg-white dark:bg-gray-900 py-10">
      <div className="max-w-7xl mx-auto px-4">
        <h1 className="text-4xl font-bold text-center text-gray-900 dark:text-white mb-6">
          About Us
        </h1>
        <p className="text-lg text-gray-700 dark:text-gray-300 mb-8 text-center">
          For over 14 years, Whitehouse Education Zone (WHEZ) has been a pillar
          of academic success in Bharatpur, Nepal. We are the leading provider
          of bridge courses CTEVT entrance, CEE(Common Entrance Examination),
          Engineering, Applied Science, Lisence, Preparation exam preparation in
          the Narayani Zone, dedicated to empowering students to achieve their
          academic aspirations and secure rewarding careers in technical fields.
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-10 text-justify">
          <div className="bg-gray-100 dark:bg-gray-800 rounded-lg p-6 shadow-md">
            <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mb-4">
              Our Mission
            </h2>
            <p className="text-gray-700 dark:text-gray-300">
              At Whitehouse Education Zone, our mission is to bridge the gap
              between your secondary education and your future in technical
              education. We achieve this by offering:
              <ul>
                <li>
                  <strong>Comprehensive Bridge Courses:</strong> Our bridge
                  courses seamlessly transition you from your SEE (Secondary
                  Education Examination) knowledge base to the demands of CTEVT
                  programs.
                </li>
                <li>
                  <strong>Targeted CTEVT Entrance Exam Preparation:</strong> We
                  provide in-depth preparation programs specifically designed
                  for various CTEVT entrance exams.
                </li>
                <li>
                  <strong>Supportive Learning Environment:</strong> We foster a
                  nurturing and encouraging environment where students feel
                  empowered to learn, ask questions, and achieve their full
                  potential.
                </li>
                <li>
                  <strong>Holistic Development:</strong> We go beyond exam
                  preparation, focusing on developing essential skills like
                  critical thinking, problem-solving, and effective
                  communication.
                </li>
              </ul>
            </p>
          </div>

          <div className="bg-gray-100 dark:bg-gray-800 rounded-lg p-6 shadow-md text-justify">
            <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mb-4">
              Our Vision
            </h2>
            <p className="text-gray-700 dark:text-gray-300">
              The transition from SEE to higher education can be challenging,
              especially when bridging the gap to +2 Science or Management
              streams. Our meticulously designed bridge courses address this
              head-on by:
              <ul>
                <li>
                  <strong>Building a Strong Foundation:</strong> We revisit and
                  solidify core subjects like Science, Mathematics, and English,
                  ensuring a strong foundation for your chosen +2 stream. Here's
                  a breakdown of how we tailor our bridge courses:
                  <ul>
                    <li>
                      <strong>+2 Science Bridge Course:</strong> We delve deeper
                      into core scientific concepts in Physics, Chemistry, and
                      Biology, going beyond the basics covered in SEE. Our
                      instructors will address any learning gaps you might have
                      and ensure you have a solid understanding of scientific
                      principles, lab practices, and mathematical applications
                      essential for excelling in +2 Science.
                    </li>
                    <li>
                      <strong>+2 Management Bridge Course:</strong> Our bridge
                      course equips you with foundational knowledge in business
                      principles, accounting fundamentals, and basic management
                      practices. We'll provide a strong understanding of key
                      concepts like marketing, finance, and human resource
                      management, giving you a head start on your +2 Management
                      program.
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>Addressing Knowledge Gaps:</strong> Through
                  assessments and personalized attention, we identify specific
                  areas where you may need more support. Whether it's grasping
                  complex scientific theories or understanding business
                  terminology, our bridge courses will ensure you have the
                  knowledge and confidence to excel in your chosen +2 stream.
                </li>
                <li>
                  <strong>Developing Subject-Specific Skills:</strong> We
                  introduce you to advanced concepts and specialized terminology
                  relevant to your chosen +2 program. The +2 Science Bridge
                  Course will introduce you to pre-calculus concepts and
                  scientific applications often encountered in +2 Science
                  programs. Our +2 Management Bridge Course will provide a
                  foundation in business vocabulary and management practices
                  specific to your chosen +2 program area.
                </li>
              </ul>
            </p>
          </div>
        </div>

        <div className="bg-gray-100 dark:bg-gray-800 dark:text-gray-300 rounded-lg p-6 shadow-md mb-10 text-justify">
          <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mb-4">
            Why Choose Whitehouse Education Zone?
          </h2>
          <ul>
            <li>
              <strong>Proven Track Record of Success:</strong> We have a
              long-standing reputation for excellence, with countless students
              securing top scores in their CTEVT entrance exams and pursuing
              successful careers in their chosen technical fields.
            </li>
            <li>
              <strong>Expert Faculty:</strong> Our team of highly qualified and
              passionate educators boasts extensive experience in both CTEVT
              curriculum and effective teaching methodologies.
            </li>
            <li>
              <strong>Personalized Attention:</strong> We understand that each
              student learns differently. We offer personalized guidance and
              support, ensuring you receive the individual attention you need to
              thrive.
            </li>
            <li>
              <strong>Comprehensive Resources:</strong> We go beyond classroom
              learning by providing a wealth of study materials, including
              textbooks, practice tests, access to our online learning portal
              with additional resources, and a dedicated library.
            </li>
            <li>
              <strong>Supportive Learning Environment:</strong> At WHEZ, you'll
              find a nurturing and encouraging atmosphere where you can learn
              collaboratively, ask questions freely, and build strong
              relationships with peers and instructors.
            </li>
          </ul>
        </div>

        <div className="text-center mb-6">
          <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mb-4">
            Join Us!
          </h2>
          <p className="text-gray-700 dark:text-gray-300 text-justify">
            Are you ready to embark on your journey to success in a rewarding
            CTEVT program, Bridge Course in +2 Science and Management? Contact
            Whitehouse Education Zone today to learn more about our bridge
            courses and CTEVT entrance exam preparation programs. We offer a
            variety of options to suit your individual needs and learning style.
          </p>
        </div>

        <div className="flex justify-center">
          <FaRegClock className="h-6 w-6 text-blue-500 mr-2" />
          <span className="text-gray-700 dark:text-gray-300">
            Operating Hours: Mon - Fri, 6 AM - 5 PM
          </span>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
