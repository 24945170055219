import React from "react";
// eslint-disable-next-line
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./HomePage";
import ContactUs from "./ContactUs";
import Register from "./Register";
import Notice from "./Notice";
import ProgramsOffered from "./ProgramsOffered";
import Gallery from "./Gallery";
import FAQs from "./FAQs";
import PrivacyPolicy from "./PrivacyPolicy";
import Terms from "./Terms";
import Article from "./Article";
import AboutUs from "./AboutUs";
import NotFound from "./NotFound";

const Navigation = () => {
  return (
   
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/register" element={<Register />} />
        <Route path="/notice" element={<Notice />} />
        <Route path="/programs-offered" element={<ProgramsOffered />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/faqs" element={<FAQs />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/article" element={<Article />} />
        <Route path="*" element={<NotFound />} /> {/* Uncomment this for 404 page */}
      </Routes>
    
  );
};

export default Navigation;
