const features = [
  {
    name: "Over a Decade of Dreams Realized",
    description:
      "We have been shaping bright futures for 14 years, turning aspirations into achievements.",
    icon: "🎓",
  },
  {
    name: "Student Success Stories Speak Volumes",
    description:
      "Our proven track record isn't just numbers, it's a testament to the success stories of countless students.",
    icon: "📈",
  },
  {
    name: "More Than Academics, We Nurture Potential",
    description:
      "We cultivate a nurturing and supportive environment where students not only learn, but also thrive.",
    icon: "🌱",
  },
  {
    name: "Building Well-Rounded Individuals",
    description:
      "Our commitment extends beyond academics, fostering confident and well-rounded individuals prepared for life's challenges.",
    icon: "🧠",
  },
  {
    name: "Igniting a Passion for Learning",
    description:
      "Our legacy isn't just results, it's about igniting a lifelong love of learning in our students.",
    icon: "🔥",
  },
  {
    name: "Empowering for Lifelong Success",
    description:
      "We equip our students with the knowledge and skills they need, not just for exams, but for success in whatever path they choose.",
    icon: "🔥",
  },
  {
    name: "A Community of Excellence",
    description:
      "Over 14 years, we've built a vibrant community of learners and educators who inspire one another.",
    icon: "🛠️",
  },
  {
    name: "Evolving with Every Student",
    description:
      "Our legacy is one of continuous improvement, shaped by the unique needs of each student who walks through our doors.",
    icon: "🌐",
  },
  {
    name: "Building Bridges to Brighter Futures",
    description:
      "We don't just educate, we bridge the gap between potential and achievement.",
    icon: "🌉",
  },
  {
    name: "The Legacy Continues",
    description:
      "Our story isn't over. We're excited to continue shaping bright futures for generations to come.",
    icon: "📖",
  },
];

export default function Features() {
  return (
    <div className="sm:py-3 bg-white dark:bg-gray-900">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-base font-semibold leading-7 text-indigo-600 dark:text-indigo-400">
            Features
          </h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-4xl">
            UnPotential. Igniting Success. Bridge the Gap Here.
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
            {features.map((feature) => (
              <div
                key={feature.name}
                className="relative pl-20 py-1 rounded-lg mb-6 shadow-md md:shadow-xl hover:shadow-2xl transition duration-300 bg-white dark:bg-gray-800">
                <dt className="text-base font-semibold leading-7 text-gray-900 dark:text-white ">
                  <div className="absolute left-0 top-0 flex items-center justify-center w-16 h-16 rounded-lg my-5 bg-gray-100 dark:bg-gray-700 ">
                    <span
                      role="img"
                      aria-label={feature.name}
                      className="text-4xl  ">
                      {feature.icon}
                    </span>
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-600 dark:text-gray-400">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
