import React from 'react';
import { Link } from 'react-router-dom';
import SocialLinks from '../../components/SocialLinks';
import QuickLinks from './QuickLinks';

const links = [
  { to: '/about-us', label: 'About' },
  { to: '/privacy-policy', label: 'Privacy Policy' },
  { to: '/terms', label: 'Terms' },
  { to: '/contact-us', label: 'Contact' },
];



export default function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-[#efefef] rounded-t-2xl shadow-xl mt-4 dark:bg-gray-900 ">
      <div className="w-full max-w-screen-xl mx-auto p-4 md:py-8">
        <div className="sm:flex sm:items-center sm:justify-between">
          <a
            href="https://whitehouse.com.np/"
            className="flex items-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse"
          >
            <img src="img/logo.png" className="h-8" alt="Whitehouse Education logo" />
            <span className="self-center text-sm md:text-2xl font-semibold whitespace-nowrap dark:text-white">
              Whitehouse Education Zone
            </span>
          </a>
          <ul className="flex flex-wrap items-center mb-6 text-sm font-medium text-gray-500 sm:mb-0 dark:text-gray-400">
            {links.map((link) => (
              <li key={link.label}>
                <Link to={link.to} className="hover:underline me-4 md:me-6">
                  {link.label}
                </Link>
              </li>
            ))}
          </ul>
        </div>

        <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
        <QuickLinks />
        <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
        <SocialLinks />
        <span className="block text-sm text-gray-500 sm:text-center dark:text-gray-400">
          © {currentYear} <a href="https://whitehouse.com.np/" className="hover:underline">Whitehouse Education Zone</a>. All Rights Reserved.
        </span>
      </div>
    </footer>
  );
}
