import React from "react";

const CollegeLogos = () => {
  const colleges = [
    { name: "College A", logo: "/assets/logos/college-a.png" },
    { name: "College B", logo: "/assets/logos/college-b.png" },
    { name: "College C", logo: "/assets/logos/college-c.png" },
    { name: "College D", logo: "/assets/logos/college-d.png" },
    { name: "College E", logo: "/assets/logos/college-e.png" },
    { name: "College F", logo: "/assets/logos/college-f.png" },
    { name: "College F", logo: "/assets/logos/college-f.png" },
    { name: "College F", logo: "/assets/logos/college-f.png" },
    { name: "College F", logo: "/assets/logos/college-f.png" },
    { name: "College F", logo: "/assets/logos/college-f.png" },
    { name: "College F", logo: "/assets/logos/college-f.png" },
    { name: "College F", logo: "/assets/logos/college-f.png" },
    { name: "College F", logo: "/assets/logos/college-f.png" },
    { name: "College F", logo: "/assets/logos/college-f.png" },
    { name: "College F", logo: "/assets/logos/college-f.png" },
    { name: "College F", logo: "/assets/logos/college-f.png" },
    { name: "College F", logo: "/assets/logos/college-f.png" },
    { name: "College F", logo: "/assets/logos/college-f.png" },
    { name: "College F", logo: "/assets/logos/college-f.png" },
    { name: "College F", logo: "/assets/logos/college-f.png" },
    { name: "College F", logo: "/assets/logos/college-f.png" },
    { name: "College F", logo: "/assets/logos/college-f.png" },
    { name: "College F", logo: "/assets/logos/college-f.png" },
  ];

  return (
    <section className="py-10 bg-gray-100 dark:bg-gray-800 my-10">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl md:text-4xl font-bold text-center mb-6 text-gray-800 dark:text-white">
          Trusted by Top Colleges
        </h2>
        <div className="overflow-hidden">
          <div className="whitespace-nowrap animate-marquee">
            {colleges.map((college) => (
              <div key={college.name} className="inline-block mx-4">
                <img
                  src={college.logo}
                  alt={college.name}
                  className="h-16 w-auto object-contain shadow-md"
                />
              </div>
            ))}
            {/* Repeat the logos for continuous scrolling effect */}
            {colleges.map((college) => (
              <div key={college.name + "-duplicate"} className="inline-block mx-4">
                <img
                  src={college.logo}
                  alt={college.name}
                  className="h-16 w-auto object-contain shadow-md"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default CollegeLogos;
