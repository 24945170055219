import { useState } from "react";
import { FaWhatsapp, FaPhoneAlt, FaFacebookMessenger, FaTimes } from "react-icons/fa";
import { AiOutlineMessage } from "react-icons/ai";

export default function FloatingContact() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="fixed bottom-10 right-5 z-50">
      {/* Floating Button */}
      <div
        className={`transition-all duration-300 ease-in-out ${
          isOpen ? "rotate-45" : ""
        } bg-blue-600 text-white rounded-full p-4 shadow-lg cursor-pointer`}
        onClick={() => setIsOpen(!isOpen)}
      >
        {isOpen ? <FaTimes size={24} /> : <AiOutlineMessage size={24} />}
      </div>

      {/* Contact Options */}
      <div
        className={`flex flex-col space-y-3 absolute right-0 bottom-16 transition-all duration-500 ease-in-out ${
          isOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
      >
        <a
          href="https://wa.me/your-phone-number"
          target="_blank"
          rel="noopener noreferrer"
          className="bg-green-500 hover:bg-green-400 transition-colors text-white p-3 rounded-full flex items-center justify-center shadow-lg"
          title="WhatsApp"
        >
          <FaWhatsapp size={20} />
        </a>
        <a
          href="tel:+123456789"
          className="bg-blue-500 hover:bg-blue-400 transition-colors text-white p-3 rounded-full flex items-center justify-center shadow-lg"
          title="Phone Call"
        >
          <FaPhoneAlt size={20} />
        </a>
        <a
          href="https://m.me/your-messenger-id"
          target="_blank"
          rel="noopener noreferrer"
          className="bg-blue-600 hover:bg-blue-500 transition-colors text-white p-3 rounded-full flex items-center justify-center shadow-lg"
          title="Messenger"
        >
          <FaFacebookMessenger size={20} />
        </a>
      </div>
    </div>
  );
}
