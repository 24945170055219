import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/swiper-bundle.css";

// Sample testimonials data
const testimonials = [
  {
    name: "Jane D",
    position: "CEO",
    avatar: "https://pagedone.io/asset/uploads/1696229969.png",
    rating: 4.9,
    comment:
      "Pagedone has made it possible for me to stay on top of my portfolio and make informed decisions quickly and easily.",
  },
  {
    name: "Harsh P.",
    position: "Product Designer",
    avatar: "https://pagedone.io/asset/uploads/1696229994.png",
    rating: 4.9,
    comment:
      "Thanks to pagedone, I feel more informed and confident about my investment decisions than ever before.",
  },
  {
    name: "Alex K.",
    position: "Design Lead",
    avatar: "https://pagedone.io/asset/uploads/1696230027.png",
    rating: 4.9,
    comment:
      "The customer service team at pagedone went above and beyond to help me resolve a billing issue.",
  },
];

const SwiperTestimonials = () => {
  return (
    <section className="py-10 mt-20 bg-white dark:bg-gray-900">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mb-16 text-center">
          <span className="text-sm text-indigo-600 font-medium block mb-2 dark:text-indigo-400">
            TESTIMONIAL
          </span>
          <h2 className="text-4xl text-gray-900 font-bold dark:text-white">
            Celebrating Our Students!
          </h2>
        </div>
        <Swiper
          modules={[Autoplay]}
          className="mySwiper"
          spaceBetween={30}
          autoplay={{ delay: 3000, disableOnInteraction: false }} // Set autoplay
          loop={true} // Loop the slides
          effect="fade"
          slidesPerView={1} // Show one slide at a time
          grabCursor={true} // Show grab cursor
          style={{ overflow: "hidden" }} // Hide overflow
        >
          {testimonials.map((testimonial, index) => (
            <SwiperSlide key={index}>
              <div className="group bg-white dark:bg-gray-800 shadow-xl hover:shadow-2xl border border-gray-200 dark:border-gray-700 rounded-xl p-8 transition-all duration-500 w-full mx-auto hover:border-indigo-600">
                <div className="flex items-center mb-4 gap-2">
                  <span className="text-amber-500 text-3xl font-semibold">
                    {testimonial.rating}★
                  </span>
                </div>
                <p className="text-lg text-gray-700 leading-7 transition-all duration-500 pb-6 group-hover:text-gray-900 dark:text-gray-300 group-hover:dark:text-white">
                  "{testimonial.comment}"
                </p>
                <div className="flex items-center gap-4 border-t border-gray-200 pt-4 dark:border-gray-700">
                  <img
                    className="rounded-full h-12 w-12 object-cover"
                    src={testimonial.avatar}
                    alt="avatar"
                  />
                  <div>
                    <h5 className="text-gray-900 font-semibold transition-all duration-500 mb-1 dark:text-white">
                      {testimonial.name}
                    </h5>
                    <span className="text-sm leading-5 text-gray-500 dark:text-gray-400">
                      {testimonial.position}
                    </span>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default SwiperTestimonials;
