import React, { useEffect, useState } from "react";

const TrackRecord = () => {
  const [students, setStudents] = useState(0);
  const [courses, setCourses] = useState(0);
  const [scholarships, setScholarships] = useState(0);

  useEffect(() => {
    // Animation effect for numbers
    const duration = 2000; // duration in milliseconds

    const incrementStudents = () => {
      const step = Math.ceil(100000 / (duration / 100));
      const interval = setInterval(() => {
        setStudents((prev) => {
          if (prev >= 100000) {
            clearInterval(interval);
            return 100000;
          }
          return Math.min(prev + step, 100000);
        });
      }, 100);
    };

    const incrementCourses = () => {
      const step = Math.ceil(15 / (duration / 100));
      const interval = setInterval(() => {
        setCourses((prev) => {
          if (prev >= 15) {
            clearInterval(interval);
            return 15;
          }
          return Math.min(prev + step, 15);
        });
      }, 100);
    };

    const incrementScholarships = () => {
      const step = Math.ceil(100000 / (duration / 100));
      const interval = setInterval(() => {
        setScholarships((prev) => {
          if (prev >= 100000) {
            clearInterval(interval);
            return 100000;
          }
          return Math.min(prev + step, 100000);
        });
      }, 100);
    };

    incrementStudents();
    incrementCourses();
    incrementScholarships();
  }, []);

  return (
    <section className="py-10 my-10 px-4 sm:px-6 lg:px-8  text-white dark:from-gray-800 dark:to-gray-900 dark:text-white dark:rounded-xl dark:shadow-xl">
      <div className="max-w-7xl mx-auto">
        <div className="mb-8 text-center">
          <h2 className="text-3xl md:text-4xl font-bold mb-2 text-black dark:text-white">
            Trusted by thousands of <br /> students worldwide
          </h2>
          <p className="text-lg md:text-xl text-gray-600 dark:text-gray-300">
            We have been empowering learners for 14 years.
          </p>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          <div className="bg-gradient-to-r from-blue-500 to-blue-700 p-6 rounded-lg shadow-lg text-center transition duration-300 transform hover:scale-105">
            <h3 className="text-3xl font-bold">{students}+</h3>
            <p className="text-gray-200">Students enrolled</p>
          </div>
          <div className="bg-gradient-to-r from-green-500 to-green-700 p-6 rounded-lg shadow-lg text-center transition duration-300 transform hover:scale-105">
            <h3 className="text-3xl font-bold">{courses}+</h3>
            <p className="text-gray-200">Courses available</p>
          </div>
          <div className="bg-gradient-to-r from-purple-500 to-purple-700 p-6 rounded-lg shadow-lg text-center transition duration-300 transform hover:scale-105">
            <h3 className="text-3xl font-bold">
              Rs. {scholarships.toLocaleString()}
            </h3>
            <p className="text-gray-200">Scholarships allocated</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TrackRecord;
