import CollegeLogos from "../components/CollegeLogos";
import AboutSection from "./Home/AboutSection";
import Features from "./Home/Features";
import HeroSection from "./Home/HeroSection";
// import HomeCarousel from "./Home/HomeCarousel";
import ImageGallery from "./Home/ImageGallery";
import JoinBroadcast from "./Home/JoinBroadcast";
import NoticeBoard from "./Home/NoticeBoard";
import ProgramShowcase from "./Home/ProgramShowcase";
import SwiperTestimonials from "./Home/Testomonials";
import TrackRecord from "./Home/TrackRecord";

export default function HomePage() {
  return (
    <div className="container mx-auto px-4 md:px-10">
      {/* <HomeCarousel /> */}
      <HeroSection />
      <NoticeBoard />
      <AboutSection />
      <ProgramShowcase />
      <JoinBroadcast />
      <Features />
      <TrackRecord />
      <JoinBroadcast />
      <ImageGallery />
      <CollegeLogos />
      <SwiperTestimonials />
    </div>
  );
}
